import API from './api'

const isBrowser = typeof window !== `undefined`
const getHostUser = () => (localStorage.getItem(`token`) ? true : false)

export const isHostLoggedIn = () => {
  if (!isBrowser) return false
  // verify their token
  const token = localStorage.getItem(`token`)
  if (token) {
    return API.post(`/token_verify/`, {
      token: localStorage.getItem(`token`),
    })
      .then(res => true)
      .catch(function(error) {
        console.log(error)
        // errors are usually like the token expired, so log in again
        localStorage.removeItem(`token`)
        return false
      })
  } else {
    return new Promise(function(resolve, reject) {
      resolve(false)
    })
  }
}

export const hostLogout = callback => {
  if (!isBrowser) return
  localStorage.removeItem(`token`)
  callback()
}
