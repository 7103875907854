/**
 * Host Login Page
 * 
 * Log a host into the host dashboard so that they can run
 * their games.
 */

import React from 'react'
import { navigate } from 'gatsby'
import Fade from 'react-reveal/Fade'
import Alert from '../../components/alert'
import { isHostLoggedIn } from '../../utils/host-auth'
import API from '../../utils/api'
import Background from '../../components/background'
import Image from '../../components/image'
import FloatingInput from '../../components/floating-input'

export default class HostLoginPage extends React.Component {
  constructor(props) {
    super(props)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      logged_in: false,
      username: ``,
      password: ``,
      alertShow: false,
    }
  }

  componentDidMount() {
    isHostLoggedIn().then(loggedIn => {
      if (loggedIn) {
        navigate(`/host/`)
      }
      return null
    })
  }

  handleSubmit(event, data) {
    event.preventDefault()

    API.post(`token_auth/`, data)
      .then(res => {
        console.log(res)
        localStorage.setItem(`token`, res.data.token)
        this.setState({
          logged_in: true,
          username: res.data.user.username,
        })
        navigate(`/host/`)
      })
      .catch(error => {
        console.log(error)
        this.setState({ alertShow: true })
      })
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  render() {
    return (
      <Fade>
        <>
          <div className={`login-page`} style={{ minHeight: `100vh`, overflow: `hidden`, maxWidth: `100%` }}>
            <Background alt="Watson Login Screen" filename="login.jpg" />
            <div
              style={{
                maxWidth: `400px`,
                margin: `auto`,
              }}>
              <div className={`mx-4 mt-5 mb-4`}>
                <Image alt="Watson Adventures" filename="watson_logo.png" />
              </div>

              <div
                className="mx-4 px-5 py-5"
                style={{
                  //margin: '100px auto',
                  backgroundColor: `#F5F2ED`,
                  position: `relative`,
                  zIndex: `10`,
                }}>
                <h1 className={`subtitle h3 text-uppercase mb-4`}>Host Login</h1>
                <form
                  className={`form-group-wrapper mb-3`}
                  method="post"
                  onSubmit={event => {
                    this.handleSubmit(event, this.state)
                  }}>
                  <FloatingInput
                    name="username"
                    placeholder={`Username`}
                    onChange={this.handleUpdate}
                    value={this.state.username}
                  />
                  <FloatingInput
                    name="password"
                    type="password"
                    placeholder={`Password`}
                    onChange={this.handleUpdate}
                    value={this.state.password}
                  />

                  <input className={`btn btn-block btn-secondary`} type="submit" value="Log In" />
                </form>
                <p className={`text-center mt-4 mb-0`}>
                  <a href={`//${process.env.ADMIN_DOMAIN}/password/reset/`}>Forgot your password?</a>
                </p>
              </div>
            </div>
            <Alert type="danger" show={this.state.alertShow} onClose={() => this.setState({ alertShow: false })}>
              <p className={`mb-0`}>Incorrect username or password</p>
            </Alert>
          </div>
        </>
      </Fade>
    )
  }
}
